<template>
  <div
    v-if="infoSeguimento.datasRoutina.length > 0"
  >
    <b-row
      v-for="(row, index) in infoSeguimento.datasRoutina"
      :key="`seguimento-date-${index}`"
    >
      <b-col md="12">
        <validation-provider
          #default="{ errors }"
          rules="required"
        >
          <b-form-group
            :label="formateDate(row.sw100s07)"
            :class="errors.length > 0 ? 'is-invalid':null"
          >
            <v-select
              v-model="row.sw100s04"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="formVars.optionsStatusDate"
              append-to-body
              :calculate-position="withPopper"
              :clearable="true"
              label="txt"
              item-text="txt"
              item-value="id"
            >
              <template #option="{ txt }">
                {{ txt }}
              </template>
              <div slot="no-options">
                {{ $t('Sem resultados') }}
              </div>
            </v-select>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import { format, parseISO } from 'date-fns'
import { ValidationProvider } from 'vee-validate'
import { eventsCustomSelects } from '@core-custom/mixins/geral'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  mixins: [eventsCustomSelects],
  data() {
    return {
      sw100s04: [],
    }
  },
  computed: {
    ...mapGetters('seguimentos', ['infoSeguimento', 'formVars']),
  },
  mounted() {
    if (this.infoSeguimento.datasRoutina.length > 0) {
      this.infoSeguimento.datasRoutina.forEach(row => {
        const oRow = row

        const optionSel = this.formVars.optionsStatusDate.find(o => Number(o.id) === Number(row.sw100s04))
        if ((optionSel !== null) && (optionSel !== undefined)) {
          oRow.sw100s04 = optionSel
        } else {
          oRow.sw100s04 = null
        }
      })
    }
  },
  methods: {
    clear() {
      if (this.infoSeguimento.datasRoutina.length > 0) {
        this.infoSeguimento.datasRoutina.forEach(row => {
          const oRow = row
          oRow.sw100s04 = null
        })
      }
    },
    getData() {
      return {
        dates: this.infoSeguimento.datasRoutina,
      }
    },
    formateDate(date) {
      let sDate = format(parseISO(date), 'yyyy-MM-dd HH:mm')
      sDate = sDate.replace('00:00', '')

      return sDate
    },
  },
}
</script>
